(function(){
  const page = document.getElementById('page-content');
  const sideMenu = document.getElementById('side-menu');
  const showMenuButton = document.getElementById('show-menu-button');
  
  const toggleMenu = () => {
    [
      page,
      sideMenu,
      showMenuButton,
    ].forEach(e => e.classList.toggle('slided'));
  };
  
  const hideMenu = () => {
    [
      page,
      sideMenu,
      showMenuButton,
    ].forEach(e => e.classList.remove('slided'));
  };
  
  document.querySelectorAll('.page-scroll').forEach(e => e.onclick = hideMenu);
  
  showMenuButton.onclick = toggleMenu;
})();